import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import Switch from 'react-switch'
import {
  Container,
  HeaderImage,
  HeaderDescription,
  HeaderSwitchWrapper,
  BloggerHeader,
  MenuWrapper,
  HeaderButtonsWrapper
} from './style'
import { Theme, useTheme, LOCAL_STORAGE_THEME_KEY } from '../../providers/theme'
import useLocalStorage from '../../hooks/useLocalStorage'
import Burger from '../burger'
import Menu from '../menu'

const Header: React.FC = () => {
  const pathname = usePathname()
  const { theme, setTheme } = useTheme()
  const [_, setSelectedTheme] = useLocalStorage(
    LOCAL_STORAGE_THEME_KEY,
    Theme.LIGHT
  )

  const node = React.useRef()
  const [open, setOpen] = React.useState(false)

  const handleThemeSwitch = () => {
    const selectedTheme = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
    setTheme(selectedTheme)
    setSelectedTheme(selectedTheme)
  }

  return (
    <Container>
      <MenuWrapper>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} />
      </MenuWrapper>

      {pathname !== '/' && (
        <BloggerHeader>
          <Link href="/">
            <HeaderImage>
              <Image
                className="profileImage"
                src="/me-2.jpeg"
                alt="Ricardo Borges"
                width={75}
                height={75}
              />
            </HeaderImage>
          </Link>
          <HeaderDescription>
            <h1>
              <Link href="/">Ricardo Borges</Link>
            </h1>
            <small>
              <i>Personal blog</i>
            </small>
          </HeaderDescription>
        </BloggerHeader>
      )}
      <HeaderSwitchWrapper>
        <HeaderButtonsWrapper>
          {pathname !== '/' && (
            <Link className="menu-button" href="/">
              Home
            </Link>
          )}
          {pathname === '/' && (
            <Link
              className="menu-button"
              href="/blog"
              style={{ marginRight: 20 }}
            >
              Blog
            </Link>
          )}
        </HeaderButtonsWrapper>

        <Switch
          onChange={() => handleThemeSwitch()}
          checked={theme === Theme.DARK}
          onColor={'#000'}
          offColor={'#000'}
          checkedIcon={<img src={'/moon-icon.svg'} />}
          uncheckedIcon={<img src={'/sun-icon.svg'} />}
        />
      </HeaderSwitchWrapper>
    </Container>
  )
}

export default Header
