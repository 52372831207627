import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  padding: 30px 20px;
`

export const BloggerHeader = styled.header`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    display: none;
  }
`

export const HeaderImage = styled.div`
  margin-right: 10px;

  img {
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }
`

export const HeaderDescription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 1.9em;
    a {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.2em;
    }
  }
`

export const HeaderSwitchWrapper = styled.section`
  margin-left: auto;
  display: inline-flex;
  align-items: center;

  & img {
    width: 24px;
    margin-top: 2px;
    margin-left: 2px;
  }
`

export const MenuWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

export const HeaderButtonsWrapper = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`
