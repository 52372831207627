import styled from 'styled-components'

export const StyledMenu = styled.nav<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colors.menuBackground};
  transform: ${props => (props.$open ? 'translateX(0)' : 'translateX(-100%)')};
  box-shadow: ${props => (props.$open ? props.theme.colors.menuShadow : '')};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`
