import React, { useEffect } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { Analytics } from '@vercel/analytics/react'
import GlobalStyle from '../styles/global'
import AuthProvider from '../providers/auth'
import { ThemeProvider } from '../providers/theme'
import { StyleComponentThemeProvider } from '../providers/style-component-theme'
import { useRouter } from 'next/router'
import * as gtag from '../config/gtag'
import Header from '../components/header'
import Footer from '../components/footer'
import { MainContainer } from '../styles'

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // GA
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ThemeProvider>
      <AuthProvider>
        <>
          <Analytics />
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <meta
              name="description"
              content="Personal website and blog by Ricardo Borges"
            />
          </Head>
          <StyleComponentThemeProvider>
            <MainContainer>
              <Header />
              <Component {...pageProps} />
              {router.route !== '/' && <Footer />}
              <GlobalStyle />
            </MainContainer>
          </StyleComponentThemeProvider>
        </>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default MyApp
