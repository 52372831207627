export const lightTheme = {
  colors: {
    background: '#ffffff',
    secondBackground: '#ffffff',
    text: '#323232',
    primary: '#8257e6',
    error: '#F00',
    tag: '#808080',
    buttonBackground: '#000',
    buttonTextColor: '#fff',
    buttonHoverColor: '#303030',
    burgerMenuColor: '#000',
    menuBackground: '#fff',
    menuShadow: '1px 1px 5px #ccc'
  }
}

export const darkTheme = {
  colors: {
    background: '#191919',
    secondBackground: '#202020',
    text: '#fff',
    primary: '#8257e6',
    error: '#F00',
    tag: '#c0c0c0',
    buttonBackground: '#fff',
    buttonTextColor: '#000',
    buttonHoverColor: '#DCDCDC',
    burgerMenuColor: '#fff',
    menuBackground: '#000',
    menuShadow: 'none'
  }
}

export default lightTheme
