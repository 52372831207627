import styled from 'styled-components'

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 1080px;
    margin: 0 auto;
    align-items: stretch;
    height: 100vh;
  }
`
