import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    transition: background-color 0.8s ease;    
    background-color: ${props => props.theme.colors.background};
    font: 400 1rem Roboto, sans-serif;

    :not(code), a {
      transition: color 0.8s ease;
      color: ${props => props.theme.colors.text};
    }
  }

  header * {
    transition: color 0.8s ease;
    color: ${props => props.theme.colors.text};
  }

  .menu-button {
    background-color: ${props => props.theme.colors.buttonBackground};
    color: ${props => props.theme.colors.buttonTextColor};
    padding: 10px 15px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    margin: 5px;
    height: 40px;

    &:hover {
      background: ${props => props.theme.colors.buttonHoverColor};
    }
  }

  .fade-in-image { animation: fadeIn 5s; }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

`
