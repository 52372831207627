import React from 'react'
import { StyledMenu } from './style'

export type MenuProps = {
  open: boolean
}

const Menu: React.FC<MenuProps> = ({ open }) => {
  return (
    <StyledMenu $open={open}>
      <a href="/">Home</a>
      <a href="/blog">Blog</a>
    </StyledMenu>
  )
}

export default Menu
